<template>
  <div
    class="z-30 flex items-center justify-center absolute top-0 left-0 h-screen w-full">
    <div
      class="flex items-center justify-center bg-gray-800 bg-opacity-50 h-full md:w-full fixed">
      <div class="bg-white w-96 h-profile-card rounded-lg relative select-none">
        <div class="absolute right-5 top-5" @click="closeModal">
          <svg
            class="ml-auto fill-current text-grey w-5 h-5 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
        </div>
        <div
          class="w-full mt-8 flex flex-col items-center space-y-5 border-b pb-4">
          <div
            v-if="userAccount.profileImage"
            class="flex-shrink-0 rounded-full overflow-hidden">
            <img
              :src="userAccount.profileImage"
              class="w-28 h-28 object-cover" />
          </div>
          <div
            v-else
            class="w-24 h-24 bg-grey-500 rounded-full whitespace-nowrap" />
          <h1 class="text-lg font-semibold" :style="{ color: accentColor }">
            {{ userAccount.firstName }} {{ userAccount.lastName }}
          </h1>
        </div>
        <div>
          <div
            class="mx-5 mt-3 h-16 overflow-auto"
            v-for="(field, index) of fieldNames"
            :key="`profile-card-field-${index}`">
            <p class="text-grey-500" :style="{ color: accentColor }">
              {{ field }}
            </p>
            <p class="text-grey-500">{{ fieldAnswers[index] }}</p>
          </div>
        </div>
        <div
          class="absolute bottom-3 select-none w-full flex space-x-2 justify-center">
          <div
            class="rounded-full h-10 w-40 flex justify-center items-center text-white text-sm"
            :style="{ backgroundColor: accentColor }">
            Message
          </div>
          <div
            class="rounded-full h-10 w-40 flex justify-center items-center text-white text-sm"
            :style="{ backgroundColor: accentColor }">
            Request Call

            <img
              :src="require('@/assets/call-icon-white.svg')"
              class="h-4 w-4 ml-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_BRANDING } from "@/store/getters.type";

  export default {
    props: ["user"],
    data() {
      return {
        userAccount: this.user,
        fieldNames: [],
        fieldAnswers: {},
        primaryColor: "",
        accentColor: "",
      };
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
    },
    computed: {
      ...mapGetters({ getBranding: GET_BRANDING }),
    },
    mounted() {
      document.body.style.overflow = "hidden";
    },
    beforeDestroy() {
      document.body.style.overflow = null;
    },
    unmounted() {
      document.body.style.overflow = null;
    },
    created() {
      this.accentColor = this.getBranding.accentColor;
      this.fieldAnswers = this.userAccount.profile
        ? JSON.parse(JSON.stringify(this.userAccount.profile))
        : { 0: "", 1: "", 2: "", 3: "" };
      this.fieldNames = this.getBranding.profileFields;
    },
  };
</script>
